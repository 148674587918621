import { Call } from '@mui/icons-material'
import React from 'react'
import { Link } from 'react-router-dom'

const ReachToUs = () => {
  return (
    <div>
      <div className="flex mt-10 bg-pink-50">
        <div className='flex flex-col gap-5 p-2 md:flex-row md:p-16'>
          <div className='flex-col flex w-full md:w-[55%]'>

            <p className="text-2xl font-semibold text-black ">Reach to us</p>
            <p className='pt-2 text-black'>
              If you have any Query or Suggestion, we are happy to hear you. You can mail us on
              enquiry@adwoltelectricals.com or also expect a phone call from us through our contact page.
            </p>
          </div>
          <div className=' flex justify-center items-center h-14 mt-10 w-full md:w-[45%]'>
            <Link to="tel:+919988879333" target="_blank" rel="noopener noreferrer" className='text-black md:text-xl lg:text-2xl font-bold flex bg-white justify-center items-center p-2 hover:bg-gradient-to-r from-pink-700 to-[#da3035] hover:text-white'><Call />+91 9988879333</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReachToUs
