import { Call } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import axios from 'axios'
import Loader from 'Components/Common/Loader'
import { useFormik } from 'formik'
import TopBrand from 'Pages/AboutUs/topbrand'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import ContactSchema from 'Schemas'
import { postFormDataFn } from 'Services/ContactUs'
import Brands from './brands'
import OurPatner from 'Pages/AboutUs/topbrand'
import SliderPage from './slider'
import cable from "../Home/image.png";
import cable1 from "../Products/5.jpg"
import home from "../Home/home.png"
import img from "../Products/House-wires-1-1.png"
import img1 from "../Products/2.jpg"
import img2 from "../Products/3.jpg"
import img3 from "../Products/4.jpg"
import img4 from "../Products/5.jpg"
import img5 from "../Products/6.jpg"
import img6 from "../Products/7.jpg"
import { baseURL } from 'Configs/axios'

/**
 * Home component.
 * Represents the home page of the application.
 * @returns {JSX.Element} Home component JSX.
 */
const Home = () => {


  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [slider, setSlider] = useState();



  const blogFn = async () => {

    const response = await axios.get(`${baseURL}/blog/blog-api/`,
      {
        headers: {
          Authorization: 'Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0',
        },
      });
    setData(response.data.data);
  };

  useEffect(() => {
    blogFn();
  }, []);

  const sliderFn = async () => {

    const response = await axios.get(`${baseURL}/blog/Slider-api/`,
      {
        headers: {
          Authorization: 'Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0',
        },
      });
    setSlider(response.data.data);
  };

  useEffect(() => {
    sliderFn();
  }, []);


  const [toggleForm, setToggleForm] = useState(false)
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,

  }

  const [successMessage, setSuccessMessage] = useState('')

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.')
        handleReset()
        setToggleForm(false);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      console.error("Error response:", error);
    }
  })

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    mobile_no: '',
    customer_messages: '',
  }

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } =
    useFormik({
      initialValues,
      validationSchema: ContactSchema,
      onSubmit: (values) => {
        const formData = new FormData()
        Object.keys(values).forEach((key) => formData.append(key, values[key]))
        postFormData(formData)
      },
    })
  const products = [
    {
      id: 1,
      image: img,
      Title: 'HOUSE WIRE',
      details: "Adwolt offers house wires for domestic and commercial purposes. These wires are safe, efficient to use.",
    },
    {
      id: 2,
      image: img1,
      Title: 'SUBMERSIBLE 3 CORE FLAT CABLES',
      details: "Adwolt offers house wires for domestic and commercial purposes. These wires are safe, efficient to use.",
    },
    {
      id: 3,
      image: img2,
      Title: 'MULTICORE ROUND FLEXIBLE WIRE',
      details: "Provides flat submersible cables mainly designed to use in submersible pumps in a deep well.",
    },
    {
      id: 4,
      image: img3,
      Title: 'COMPUTER AND NETWORKING CABLES',
      details: "Multicore wires by Adwolt is a perfect solution to use in houses, workspace or in commercial place.",
    },
    {
      id: 5,
      image: img4,
      Title: 'TELEPHONE AND SWITCHBOARD CABLES',
      details: "Coaxial cables are copper-based wires to transmit data, video, and voice communications to end-use.",
    },
    {
      id: 6,
      image: img5,
      Title: 'C.C.T.V. AND SPEAKER CABLES',
      details: "We provide service wires which are in huge demand and safer to use for household purposes.",
    },


  ]

  return (
    <div className="relative overflow-hidden bg-white bg- ">

      <Slider {...settings} className="">
        {slider?.map((i) => (
          <div className='relative'>
            <img src={i.image} alt={i.title} className='w-full lg:h-[900px] md:h-[600px] h-[350px]' />
            <p
              className='absolute lg:text-6xl md:text-4xl text-3xl font-bold text-center text-black  md:top-[30%] top-[20%]
               left-[10%]'
              dangerouslySetInnerHTML={{ __html: `<span style="all: unset;">${i.title}</span>` }}
            />
            {/*  <p
              className='absolute lg:text-6xl md:text-4xl text-3xl font-bold text-center text-black  md:top-[37%] top-[33%]
               left-[10%]'
              dangerouslySetInnerHTML={{ __html: `<span style="all: unset;">${i.title.slice(30, 60)}</span>` }}
            /> */}
            {/*  <p 
              dangerouslySetInnerHTML={{ __html: `<span style="all: unset;">${i.title}</span>` }}
            >
              {i.title}
            </p> */}
          </div>
        ))}
      </Slider>


      <div className='absolute flex items-center justify-center text-black lg:top-[10%] md:top-[5%] left-[15%] w-[70%] lg:h-36 md:h-28 md:block hidden'>
        <div className='grid grid-cols-3 gap-5 text-center'>
          <div className='flex flex-col items-center justify-center p-4 pb-2 bg-white'>
            <Link to="/blogs">
              <img src={home} alt='' className='lg:h-48 md:h-40' />
              <p className='p-2 text-xl font-bold'>Latsest Blogs</p>
            </Link>
          </div>

          <div className='flex flex-col items-center justify-center p-4 bg-white '>
            <Link to="/gallery">
              <img src={cable} alt='' className='h-48 md:h-40 ' />
              <p className='p-2 text-xl font-bold'>Gallery</p>
            </Link>
          </div>
          <div className='flex flex-col items-center justify-center p-4 bg-white'>
            <Link to="/products">
              <img src={cable1} alt='' className='h-48 md:h-40' />
              <p className='p-2 text-xl font-bold'>Product</p>
            </Link>
          </div>
        </div>

      </div>
      <div className="relative flex flex-col items-center justify-center w-full gap-3 px-4 mb-10 bg-white">
        <p className="w-[80%] pt-20 text-3xl font-bold text-center text-black md:text-4xl lg:w-full">
          <span className="text-[#93c83f]">INDUSTRY</span> SOLUTION WE OFFER
        </p>
        <p className=" lg:w-[60%] text-center text-black ">
          manufacturer Industry We offer a variety of products, including electrical wires, power cables, and communication cables, all designed to meet stringent safety standards.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-[400px_1fr] gap-4">
          <div className="flex flex-col order-2 gap-5 lg:order-1">
            <p className="text-2xl font-extrabold text-[#93c83f] toUpper">
              Consumer Electronics Solutions
            </p>
            <p className="text-black">
              Effective surveillance plays a vital role in enhancing transportation quality by recognizing suspicious items or activities.
              <br /><br />
              <strong>Automotive</strong><br />
              Our products are designed for durability and reliability, suitable for power distribution, data communication, and safety systems.
              <br /><br />
              <strong>Construction</strong><br />
              Our extensive range of products is designed to meet the diverse requirements of various sectors, ensuring safety, reliability, and performance..
            </p>
            <Link to={"/products"} className="w-40 p-2 text-center text-blue-500 border border-blue-300 cursor-pointer rounded-3xl hover:bg-blue-500 hover:text-white">
              Read More
            </Link>
          </div>
          <img src={home} alt="Safe City CCTV Camera" className='items-start order-1 h-[300px] md:h-[500px] mx-auto lg:order-2' />
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mb-10 bg-white ">
        <p className="text-xl font-bold text-black md:text-4xl ">
          {' '}
          <strong className="text-[#93c83f]">OUR</strong> PRODUCTS
        </p>
        <div className="bg-teal-400 h-[2px] w-24"></div>
        <p className="px-4 pt-5 font-medium text-center text-black lg:w-[70%] ">
          Our product lineup includes durable electrical wires and heavy-duty power cables for reliable power distribution in residential, commercial, and industrial applications.
        </p>
        <div className='grid grid-cols-1 gap-2 lg:grid-cols-3 md:grid-cols-2 lg:w-[60%] w-full'>
          {products.map((detail) => (
            <div key={detail.id} className='flex flex-col items-center gap-3 p-4'>
              <div className='relative p-2 overflow-hidden border-4'>
                <img
                  src={detail.image}
                  alt=''
                  className='h-56 duration-200 lg:h-72 md:h-60 hover:scale-105'
                />
                <div className='absolute inset-0 flex flex-col items-center justify-center transition-opacity duration-300 bg-black bg-opacity-50 opacity-0 hover:opacity-100'>
                  <p className='text-xl font-medium text-center text-white'>{detail.Title}</p>
                  <button
                    className='mt-2 p-2 font-semibold text-[#da3035] bg-white border border-white cursor-pointer hover:text-white hover:bg-[#da3035]'
                    onClick={() => setToggleForm(true)}
                  >
                    View Product
                  </button>
                </div>
              </div>
              <p className='text-xl font-medium text-center text-black'>{detail.Title}</p>

            </div>
          ))}
        </div>




        {/*  <div onClick={() => setToggleForm(true)} className="cursor-pointer flex gap-3 h-[200px] md:h-[300px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden">
            <p className='w-full h-[80%] overflow-hidden'>
              <img
                src="https://www.adwolt.com/img/adwolt-housing-wires.4e2462e2.jpeg" alt=''
                className="w-full h-full duration-200 hover:scale-105"
              />
            </p>
            <p className="text-[#07c2d3] p-2 text-center font-bold text-sm xl:text-base">House wires</p>
          </div> */}

        {/*  <div className="flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden">
            <p onClick={() => setToggleForm(true)} className='cursor-pointer w-full h-[80%] overflow-hidden'>
              <img
                src="https://img.etimg.com/thumb/width-420,height-315,imgsize-35920,resizemode-75,msid-101504267/news/india/indias-public-wifi-coverage-falls-way-short-of-target/wi-fi-internet-router.jpg"
                className="object-cover w-full h-full duration-200 hover:scale-105"
              />
            </p>
            <p className="text-[#f3ac1e] p-2  text-center font-bold text-sm xl:text-base">Wifi Zone</p>
          </div>
          <div onClick={() => setToggleForm(true)} className="cursor-pointer flex gap-3 h-[250px] flex-col shadow-2xl border border-gray-400 hover:shadow-[#37adf4] lg:rounded-xl overflow-hidden">
            <p className='w-full h-[80%] overflow-hidden'>
              <img
                src="https://4.imimg.com/data4/MR/RF/MY-4916988/1-500x500.jpg"
                className="object-cover w-full h-full duration-200 hover:scale-105"
              />
            </p>
            <p className="text-[#3956d0] p-2 text-center font-bold text-sm xl:text-base">Interion/EPABX</p>
          </div> */}

        <Link to="/products">
          <button type="button" className="p-4 mt-10 text-xl font-medium text-center text-white rounded-lg bg-gradient-to-r from-pink-500 to-[#da3035] hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800">View More Products</button>
        </Link>
      </div>
      <div
        className="flex justify-center items-center min-h-[100vh] bg-cover bg-center px-4"
        style={{
          backgroundImage: 'url("https://erplab.s3.amazonaws.com/fiber-wire/EPC-Companies-Step-Industries.jpg")',
          backgroundAttachment: 'fixed',
          zIndex: -1,
        }}
      >
        <div className="bg-black bg-opacity-50 lg:w-[90%] w-[90%] p-4 my-10 max-w-[1200px]">
          <div className="flex flex-col items-center md:p-10">
            <p className="text-3xl font-bold">WHY CHOOSE US</p>
            <p className="h-1 bg-[#07c2d3] w-40"></p>
            <p className="font-medium lg:w-[70%] text-center w-[80%] font-sans pt-4">
              Our dedicated team works closely with clients to understand their unique needs, offering personalized support and tailored solutions. With a focus on continuous improvement and a customer-centric approach, we strive to build lasting relationships based on trust and excellence.
            </p>
            <div className="grid grid-cols-1 gap-10 pt-4 lg:grid-cols-4 md:grid-cols-2">
              <div className="flex flex-col items-center justify-center text-center ">
                <img
                  src="https://www.cctvcameradelhi.in/images/Online-Streaming.png"
                  alt=""
                  className="duration-500 hover:rotate-180"
                />
                <p className="text-[#93c83f] text-xl font-bold">Online Streaming</p>
                <p className="text-white">
                  We utilize advanced manufacturing techniques our facility is of the highest quality.
                </p>
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <img
                  src="https://www.cctvcameradelhi.in/images/delivery.png"
                  alt=""
                  className="duration-500 hover:rotate-180"
                />
                <p className="text-[#93c83f] text-xl font-bold">On Time Delivery</p>
                <p>
                  For us, customer services is always a priority than sales. Therefore, we provide
                  On-time delivery.
                </p>
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <img
                  src="https://www.cctvcameradelhi.in/images/support.png"
                  alt=""
                  className="duration-500 hover:rotate-180"
                />
                <p className="text-[#93c83f] text-xl font-bold">Dedicated Support </p>
                <p>Dedicated Support By manufacturer Industry Security Experts. </p>
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <img
                  src="https://www.cctvcameradelhi.in/images/Customer.png"
                  alt=""
                  className="duration-500 hover:rotate-180"
                />
                <p className="text-[#93c83f] text-xl font-bold">
                  Customer
                  <br />
                  Satisfaction
                </p>
                <p>
                  We provide great customer satisfaction to help all your query with configuration
                  and installation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div
        className="flex justify-center items-center min-h-[500px] bg-cover bg-center bg-opacity-75 px-4"
        style={{
          backgroundImage: 'url("https://erplab.s3.amazonaws.com/fiber-wire/Power-Cables-Step-Cables.jpg")',
          backgroundAttachment: 'fixed',
          zIndex: -1,
        }}
      >
        <div className="flex flex-col items-center justify-center">
          <div className=" flex-col flex  justify-center items-center lg:w-[70%] w-[80%] ">
            <p className="pt-10 text-2xl font-bold text-center text-black">
              PREMIUM QUALITY ADWOLT CABLES INDUSTRY SOLUTION FOR ALL AROUND SECURITY
            </p>

            <p className="p-4 text-xl text-center text-black ">
              We are name you can trust when it comes to all-round security solutions. We understand
              that few things are inevitable, security for instance.
            </p>
            <Link to="tel:+919988879333"
              type="button"
              className="px-5 py-4 mb-2 text-xl font-medium text-center text-white bg-gradient-to-br from-[#da3035] hover:border-2 hover:border-white to-[#da3035] hover:bg-gradient-to-bl focus:outline-none rounded-2xl me-2"
            >
              <Call /> +919988879333
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-white w-full justify-center items-center max-w-[1200px] mx-auto px-4">
        <div className="flex flex-col items-center justify-center pt-10 ">
          <p className="text-3xl font-bold text-center text-black lg:text-4xl lg:p-0">
            <sapan className="text-[#93c83f]">Cables</sapan> Manufacturing Industry
          </p>
          <p className="h-[2px] bg-[#07c2d3] w-40"></p>
          <p className="p-4 pt-3 text-black lg:p-0">

          </p>
          <div className="grid grid-cols-1 lg:grid-cols-[400px_1fr] gap-4 mt-4">
            <img
              src="https://erplab.s3.amazonaws.com/fiber-wire/foliflex-industrial-cables-1.webp"
              alt=""
              className='items-start order-2 object-cover h-auto mx-auto lg:order-1'
            />

            <p className="order-1 text-black lg:order-1">
              Adwolt Wires & Cables operates at the forefront of the cables manufacturing industry, where our commitment to quality and innovation drives us to exceed industry standards. Our state-of-the-art manufacturing processes utilize advanced technology and materials to produce a wide range of wiring solutions that cater to various sectors, including construction, telecommunications, automotive, and renewable energy. In a very short span of time, we have become
              one of the most trusted and leading
              <strong> Cables manufacturer Industry</strong> Our experienced team continuously monitors market trends and technological advancements, allowing us to adapt and deliver cutting-edge products that meet the evolving needs of our customers. As a trusted partner in the cables manufacturing industry, Adwolt is dedicated to providing solutions that empower businesses and enhance connectivity across the globe.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col items-center gap-5 p-2 bg-white">

        <Brands />
      </div> */}
      {/*  <div className="flex flex-col items-center gap-5 p-2 bg-white">

        <OurPatner />
      </div> */}

      <div className="flex flex-col items-center gap-3 p-2 bg-white pv-10">
        <p className="text-3xl font-bold text-black md:text-4xl">
          <span className="text-[#93c83f]">OUR </span>LATEST BLOG{' '}
        </p>
        <p className="h-[2px] bg-[#07c2d3] w-28"></p>
        <p className="text-black">Checkout our blog articles and product updates </p>
        <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 md:gap-5 lg:w-[65%] w-full">
          {data.map((blog) => (
            <Link
              to={"/blogs"}
              key={blog.id}
              className="flex flex-col items-start justify-center w-full h-full p-6 pt-10 shadow-lg"
            >
              <p className='h-[70%]'>{blog.images && (
                <img
                  src={blog.images}
                  alt={blog.title}
                  className="object-cover w-full h-full"
                />
              )}</p>
              <p className="p-2 text-sm font-semibold text-center text-blue-500 md:text-base lg:text-lg hover:text-green-500">
                {blog.title}
              </p>
            </Link>
          ))}
        </div>


      </div>
      <SliderPage />
      {toggleForm && (
        <div className="fixed top-0 left-0 grid w-screen h-screen bg-black bg-opacity-20 place-content-center">
          <div className="bg-white w-[90vw] max-w-[500px] rounded-md p-4 relative">
            <ClearIcon
              fontSize="medium"
              className="!text-[#d33156] cursor-pointer absolute right-3 top-3"
              onClick={() => setToggleForm(false)}
            />
            <div className='flex flex-col items-center justify-center' >
              <img src='https://erplab.s3.amazonaws.com/fiber-wire/logo.png' alt='' className='w-24 h-16 md:w-32 md:h-24' />
              <p className="p-4 text-2xl font-bold text-black ">Contact Us</p>
            </div>
            <form className="flex flex-col mt-4 text-base text-gray-600" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-6">
                <div className="flex flex-col">
                  <label htmlFor="first_name" className="text-base font-bold">
                    Name
                  </label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="h-10 border border-gray-300"
                  />
                  {errors.first_name && touched.first_name ? (
                    <div className="text-sm text-red-600">{errors.first_name}</div>
                  ) : null}
                </div>

                {/* <div className="flex flex-col">
                  <label htmlFor="last_name" className="text-base font-bold">
                    Last Name
                  </label>
                  <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="h-10 border border-gray-300"
                  />
                  {errors.last_name && touched.last_name ? (
                    <div className="text-sm text-red-600">{errors.last_name}</div>
                  ) : null}
                </div> */}
              </div>

              <div className="flex flex-col">
                <label htmlFor="email" className="mt-5 text-base font-bold">
                  Email
                </label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.email && touched.email ? (
                  <div className="text-sm text-red-600">{errors.email}</div>
                ) : null}

                <label htmlFor="mobile_no" className="mt-5 text-base font-bold">
                  Phone
                </label>
                <input
                  id="mobile_no"
                  type="text"
                  name="mobile_no"
                  value={values.mobile_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="h-10 w-[100%] border border-gray-300"
                />
                {errors.mobile_no && touched.mobile_no ? (
                  <div className="text-sm text-red-600">{errors.mobile_no}</div>
                ) : null}

                <label htmlFor="customer_messages" className="mt-5 text-base font-bold">
                  Comment or Message
                </label>
                <textarea
                  id="customer_messages"
                  name="customer_messages"
                  value={values.customer_messages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="border border-gray-300 "
                  rows="2"
                  cols="50"
                />
                {errors.customer_messages && touched.customer_messages ? (
                  <div className="text-sm text-red-600">{errors.customer_messages}</div>
                ) : null}

                <div className="flex items-center justify-center mt-4">
                  {isLoading
                    ? (
                      <Loader />
                    ) : (
                      <button button
                        type="submit"
                        className="px-8 py-2 bg-gradient-to-r  from-pink-700 to-[#be272c] text-white text-base"
                      >
                        Submit
                      </button>
                    )}
                </div>
              </div>
            </form>

            {/* Success Message */}
            {successMessage && (
              <div className="mt-4 font-bold text-green-600">{successMessage}</div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Home
