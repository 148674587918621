import axios from 'axios';
import { useEffect, useState } from 'react';
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import lgHash from 'lightgallery/plugins/hash';
import ReachToUs from 'Pages/AboutUs/reachtous';
import "./style.css";
import imagesLoaded from 'imagesloaded';

const Gallery = () => {
    const [data, setData] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false); // Track loading state
    const [galleryInit, setGalleryInit] = useState(false); // Track if gallery is initialized

    const galleryFn = async () => {
        const response = await axios.get('https://m1blog.aaragroups.com/blog/gallery-api/', {
            headers: {
                Authorization: 'Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0',
            },
        });
        setData(response.data.data);
    };

    useEffect(() => {
        galleryFn();
    }, []);

    // This hook will run whenever the images data changes
    useEffect(() => {
        // Wait for all images to load before initializing lightGallery
        if (data.length > 0 && !galleryInit) {
            const images = document.querySelectorAll('.gallery-item img');
            imagesLoaded(images).on('always', () => {
                setIsLoaded(true); // Set loaded state to true
                setGalleryInit(true); // Mark gallery as initialized
            });
        }
    }, [data, galleryInit]);

    if (!isLoaded) {
        return <div>Loading gallery...</div>; // Show loading until images are ready
    }

    return (
        <div>
            <LightGallery
                elementClassNames={'masonry-gallery-demo'}
                plugins={[lgZoom, lgShare, lgHash]}
                speed={500}
                selector=".gallery-item" // Ensure selector is targeting the correct elements
            >
                <div className="grid-sizer"></div>
                <div className="relative w-full overflow-hidden md:h-[450px]">
                    <img
                        src="https://erplab.s3.amazonaws.com/fiber-wire/EPC-Companies-Step-Industries.jpg"
                        alt="Products Background"
                        className="object-cover w-full h-full"
                    />
                    <div className="absolute inset-0 bg-black opacity-25"></div>
                    <div className="absolute inset-0 flex px-[20%] items-center">
                        <p className="text-4xl font-extrabold text-white">GALLERY</p>
                    </div>
                </div>
                <div className='flex items-center justify-center w-full bg-white'>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full lg:w-[70%] gap-3 p-4'>
                        {data?.map((img) => (
                            <div key={img.id} className="gallery-item">
                                {/* Wrap the image with <a> tag */}
                                <a
                                    href={img.image}  // Set href to the full-size image
                                    className="gallery-item"
                                >
                                    <img
                                        src={img.image}
                                        alt="Gallery Image"
                                        className="border-4 border-gray-400 h-[250px] w-[400px] object-cover"
                                    />
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex flex-col items-center justify-center bg-white">
                    <div className='p-4 w-full lg:w-[70%] pb-20'>
                        <ReachToUs />
                    </div>
                </div>
            </LightGallery>
        </div>
    );
};

export default Gallery;
