import ReachToUs from 'Pages/AboutUs/reachtous'
import ClearIcon from '@mui/icons-material/Clear';
import { useState } from 'react';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';
import { postFormDataFn } from 'Services/ContactUs';
import ContactSchema from 'Schemas';
import Loader from 'Components/Common/Loader';
import img from "../Products/House-wires-1-1.png"
import img1 from "../Products/2.jpg"
import img2 from "../Products/3.jpg"
import img3 from "../Products/4.jpg"
import img4 from "../Products/5.jpg"
import img5 from "../Products/6.jpg"
import img6 from "../Products/7.jpg"
import img7 from "../Products/8.jpg"
import img8 from "../Products/9.jpg"

const Service = () => {
  const details = [
    {
      id: 1,
      image: img,
      Title: 'HOUSE WIRE',
      details: "Adwolt offers house wires for domestic and commercial purposes. These wires are safe, efficient to use.",
    },
    {
      id: 1,
      image: img1,
      Title: 'SUBMERSIBLE 3 CORE FLAT CABLES',
      details: "Submersible 3 core flat cables are specially designed for use with submersible pumps, commonly employed in water supply systems, irrigation, and deep well applications.",
    },
    {
      id: 2,
      image: img2,
      Title: 'MULTICORE ROUND FLEXIBLE WIRE',
      details: "Provides flat submersible cables mainly designed to use in submersible pumps in a deep well.",
    },
    {
      id: 3,
      image: img3,
      Title: 'COMPUTER AND NETWORKING CABLES',
      details: "Computer and networking cables are essential for establishing connections and facilitating data transmission across networks.",
    },
    {
      id: 4,
      image: img4,
      Title: 'TELEPHONE AND SWITCHBOARD CABLES',
      details: "Telephone cables are used in homes and offices, while switchboard cables are found in telephone exchanges and PBX systems.",
    },
    {
      id: 5,
      image: img5,
      Title: 'C.C.T.V. AND SPEAKER CABLES',
      details: "Coaxial cables are copper-based wires to transmit data, video, and voice communications to end-use.",
    },
    {
      id: 6,
      image: img6,
      Title: 'ALUMINIUM SINGLE CORE PVC INSULATED UNSHEATHED CABLES',
      details: "Aluminium single core PVC insulated unsheathed cables are lightweight and cost-effective, making them ideal for various indoor electrical applications.",
    },
    {
      id: 7,
      image: img7,
      Title: 'ALUMINIUM FLAT TWIN AND MULTICORE UNARMORED CABLES',
      details: "Our clients can avail from us an extensive range of Aluminium Twin Flate. We are offering a wide range of Aluminum Twin Flat cables as per client specification",
    },
    {
      id: 8,
      image: img8,
      Title: 'CO-AXIAL CABLES',
      details: "Coaxial cable design choices affect physical size, frequency performance, attenuation, power handling capabilities, flexibility, strength, and cost. The inner conductor might be solid or stranded; stranded is more flexible",
    },

    /*  {
       id: 7,
       image: "https://img.etimg.com/thumb/width-420,height-315,imgsize-35920,resizemode-75,msid-101504267/news/india/indias-public-wifi-coverage-falls-way-short-of-target/wi-fi-internet-router.jpg",
       Title: 'Wifi Zone ',
       details: "We have expertise of Wi-Fi service. We can create, install and manage WIFI service at residential and commercial projects. We are providing Wi-Fi zone to the ...",
     },
     {
       id: 8,
       image: "https://4.imimg.com/data4/MR/RF/MY-4916988/1-500x500.jpg",
       Title: 'Interion/EPABX',
       details: "GSM EPABXSolution is an unique offering that eliminates delays caused due to wireline feasibility checks and permissions. It is a …",
     },
     {
       id: 9,
       image: "https://reliablesolarpanelinstallationfirm.weebly.com/uploads/1/4/5/2/145270014/solar-panel-installation_orig.jpg",
       Title: 'Solar Plants',
       details: " a facility designed for converting solar energy into electricity through a conventional thermodynamic cycle…",
     },
     {
       id: 10,
       image: "https://3.imimg.com/data3/PP/QU/MY-2983177/fire-alarm-intrusion-detection-system.jpg",
       Title: 'Instrusion/Theft/Frire Alarms',
       details: "he first line of defense against medical problems, fire, theft and home invasion. Our systems work 24 hours per day to alert you on your mobile devices and through our 24-hour monitoring center.",
     },
     {
       id: 11,
       image: "https://www.kanexfire.com/images/product/img-1.png",
       Title: 'Fire Fighting Equipments',
       details: "Fire offers a wide range of fire protection equipment, such as Fire Extinguishers, Fire Suppression Systems, Water Mist Fire Extinguishers, and related ...",
     },
 
     {
       id: 12,
       image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR74m9prsB8Qd88J4bgEXRRodyu7zuVHO4XpQ&s",
       Title: 'Boom Barrier/ Parking System',
       details: "Integrated one-stop access and parking solution for society and corporates. An original, cutting-edge, secure solution for all parking and security-related...",
     }, */
  ]

  const [toggleForm, setToggleForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.');
        handleReset();
        setToggleForm(false);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      console.error("Error response:", error);
    }
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_no: "",
    customer_messages: ""
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } = useFormik({
    initialValues,
    validationSchema: ContactSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach(key => formData.append(key, values[key]));
      postFormData(formData);
    }
  });


  return (
    <>
      <div className="relative w-full md:h-[450px]  ">
        <img
          src="https://foliflexcables.in/wp-content/uploads/2024/01/permence.png"
          alt="Products Background"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">
          <p className="text-4xl font-extrabold text-white">PRODUCTS</p>
        </div>
      </div>
      <div className='w-full p-8 bg-white'></div>
      <div className='flex flex-col items-center justify-center pt-10 bg-white '>
        <div className=' flex center justify-center items-center lg:w-[60%] w-full'>
          <div className='grid grid-cols-1 gap-10 lg:grid-cols-3 md:grid-cols-2'>
            {details.map((detail) => (
              <div key={detail.id} className='flex flex-col items-center gap-3 p-4'>
                <div className='relative p-2 overflow-hidden border-4'>
                  <img
                    src={detail.image}
                    alt=''
                    className='object-cover duration-200 h-60 w-80 hover:scale-105'
                  />
                  <div className='absolute inset-0 flex flex-col items-center justify-center transition-opacity duration-300 bg-black bg-opacity-50 opacity-0 hover:opacity-100'>
                    <p className='text-xl font-medium text-center text-white'>{detail.Title}</p>
                    <button
                      className='mt-2 p-2 font-semibold text-[#da3035] bg-white border border-[#da3035] cursor-pointer hover:text-white hover:bg-[#da3035]'
                      onClick={() => setToggleForm(true)}
                    >
                      View Products
                    </button>
                  </div>
                </div>
                <p className='text-xl font-medium text-center text-black'>{detail.Title}</p>
                <p className='text-[#7A7A7A] lg:p-0 p-6'>{detail.details}</p>
              </div>
            ))}
          </div>


        </div>
        <div className='lg:w-[67%] w-full text-center p-4'>
          <ReachToUs />
        </div>
      </div>
      {toggleForm && (
        <div className='fixed top-0 left-0 grid w-screen h-screen bg-black bg-opacity-20 place-content-center'>
          <div className="bg-white w-[90vw] max-w-[500px] rounded-md p-4 relative">
            <ClearIcon
              fontSize='medium'
              className='!text-[#da3035] cursor-pointer absolute right-3 top-3'
              onClick={() => setToggleForm(false)}
            />
            <div className='flex flex-col items-center justify-center' >
              <img src='https://erplab.s3.amazonaws.com/fiber-wire/logo.png' alt='' className='w-24 h-16 md:w-32 md:h-24' />
              <p className="pt-4 text-2xl font-bold text-black ">Contact Us</p>
            </div>
            <form className="flex flex-col mt-4 text-base text-gray-600" onSubmit={handleSubmit}>
              <div className='grid grid-cols-1 gap-3 md:gap-6'>
                <div className='flex flex-col'>
                  <label htmlFor="first_name" className='text-base font-bold'>First Name</label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='h-10 border border-gray-300'
                  />
                  {errors.first_name && touched.first_name ? <div className="text-sm text-red-600">{errors.first_name}</div> : null}
                </div>

                {/* <div className='flex flex-col'>
                                    <label htmlFor="last_name" className='text-base font-bold'>Last Name</label>
                                    <input
                                        id="last_name"
                                        type="text"
                                        name="last_name"
                                        value={values.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className='h-10 border border-gray-300'
                                    />
                                    {errors.last_name && touched.last_name ? <div className="text-sm text-red-600">{errors.last_name}</div> : null}
                                </div> */}
              </div>

              <div className='flex flex-col'>
                <label htmlFor="email" className='mt-5 text-base font-bold'>Email</label>
                <input
                  id="email"
                  type="text"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='h-10 w-[100%] border border-gray-300'
                />
                {errors.email && touched.email ? <div className="text-sm text-red-600">{errors.email}</div> : null}

                <label htmlFor="mobile_no" className='mt-5 text-base font-bold'>Phone</label>
                <input
                  id="mobile_no"
                  type="text"
                  name="mobile_no"
                  value={values.mobile_no}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='h-10 w-[100%] border border-gray-300'
                />
                {errors.mobile_no && touched.mobile_no ? <div className="text-sm text-red-600">{errors.mobile_no}</div> : null}

                <label htmlFor="customer_messages" className='mt-5 text-base font-bold'>Comment or Message</label>
                <textarea
                  id="customer_messages"
                  name="customer_messages"
                  value={values.customer_messages}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='border border-gray-300'
                  rows="2"
                  cols="50"
                />
                {errors.customer_messages && touched.customer_messages ? <div className="text-sm text-red-600">{errors.customer_messages}</div> : null}

                <div className='flex items-center justify-center mt-4'>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <button type="submit" className='px-8 py-2 bg-gradient-to-r from-pink-700 to-[#be272c] text-white text-base'>Submit</button>
                  )}
                </div>
              </div>
              {successMessage && (
                <div className="mt-4 font-bold text-green-600">
                  {successMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default Service
