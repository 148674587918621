import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@mui/material";
import axios from "axios";
import { baseURL } from "Configs/axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { toast } from "react-toastify";

const Faq = () => {
    const [data, setData] = useState();
    const { id } = useParams()

    const getCreer = () => {
        axios.get(`${baseURL}/blog/faq-api/?page_id=5`, {
            headers: {
                //Authorization: `Token ${localStorage.getItem("token")}`,
                Authorization: "Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0",
                "Access-Control-Allow-Origin": "*",
            },
        })
            .then((res) => {
                setData(res.data?.data);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };
    useEffect(() => {
        getCreer();
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, []);
    /*  const getCreer = () => {
         axios
             .get(`${baseURL}/blog/faq-api/?page_id=${id}`, {
                 headers: {
                     Authorization: `Token ${localStorage.getItem("token")}`,
                     "Access-Control-Allow-Origin": "*",
                 },
             })
             .then((res) => {
                 setData(res?.data?.data);
                 console.log(res?.data?.data);
             })
             .catch((err) => {
                 toast.error("Error deleting blog", err?.response?.data?.message);
             });
     };
     useEffect(() => {
         getCreer();
     }, []);
     console.log("first", data) */
    return (
        <div className="relative  pt-3 bg-[#fcfcfc] bg-opacity-20  lg:mx-auto">
            <div className="flex items-start justify-center ">
                <div className="flex flex-col gap-4 p-4 pb-4 ">
                    <div className="flex flex-col gap-1">
                        <p className="font-serif text-3xl font-bold text-center text-black lg:text-4xl">
                            Frequently Asked Questions
                        </p>

                    </div>

                    {data?.map((faq, index) => (
                        <Accordion key={index} className="!bg-pink-100">
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                className="text-xl !bg-white !text-black !font-bold"
                            >
                                <Typography >{faq.question}</Typography>
                            </AccordionSummary>
                            <Divider className="bg-gray-700" />
                            <AccordionDetails className="text-xl !bg-white !text-black !font-bold">
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                </div>
            </div></div>
    );
};

export default Faq;
