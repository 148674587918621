import { LocationOn, MailOutline, PhoneIphone } from '@mui/icons-material';
import { Divider } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import bg from "../Footer/footer.jpg";
import logo from "../logo1.png"

const Footer = () => {
  return (
    <div>
      <div className='relative flex items-center justify-center w-full p-4 text-white md:h-96 h-[450px] bg-gray-50' style={{ backgroundImage: `url(${bg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className='absolute flex flex-col w-full md:gap-10 gap-3 px-4 bg-black bg-opacity-50 rounded-lg  md:flex-row md:items-center md:justify-center md:h-96 h-[450px] '>
          <div className='flex flex-row md:gap-20 '>
            <div className='flex flex-col'>
              <Link to="/">
                <img src={logo} alt='' className='w-24 md:w-44' />
              </Link>
              <div className='flex flex-col items-center justify-center text-white md:pt-10'>
                {/*  <p><LocationOn /><strong> Amritsar Office : </strong>B-2A, East Mohan Nagar, Amritsar</p> */}
                <strong><LocationOn />India</strong>
              </div>
            </div>

            <div className='flex flex-col text-white'>
              <p className='flex items-center text-xl font-semibold'>QUICK LINKS</p>
              <div className='flex flex-col gap-2 pt-4'>
                <Link to="/blogs" className="cursor-pointer">
                  <p>Blog</p>
                </Link>
                <Divider />
                <Link to="/contact-us" className="cursor-pointer">
                  <p>Contact</p>
                </Link>
                <Divider />
                <Link to="/products" className="cursor-pointer">
                  <p>Product</p>
                </Link>
                <Divider />
                <Link to="/about-us" className="cursor-pointer">
                  <p>About</p>
                </Link>
                <Divider />
                <Link to="/about-us" className="cursor-pointer">
                  <p>Leading Cables Manufacturing Industry</p>
                </Link>
              </div>
            </div>
          </div>
          <div className='flex flex-col text-white'>
            <p className='flex text-xl font-semibold md:items-center'>FOR ANY SUPPORT</p>
            <div className='flex Md:pt-4'>
              {/*   <p>Contact Person - Mr. Angad</p> */}
            </div>
            <div className='flex flex-col gap-2 pt-2'></div>
            <p className='flex gap-2 cursor-pointer'><MailOutline />enquiry@adwoltelectricals.com</p>
            <Link to="tel:+919988879333" className='flex gap-2 pt-3 cursor-pointer'><PhoneIphone fontSize='medium' />Phone: +91 9988879333</Link>
            <Link to="tel:+919988879333" className='flex gap-2 pt-3 cursor-pointer'><PhoneIphone fontSize='medium' />Phone: +91 9988979333</Link>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center bg-gradient-to-r from-[#ec3237] to-[#da3035] justify-center py-4 text-center text-white px-4">
        Copyright © 2024 adwolt.com | <a href="https://www.aaragroups.com/" target="_blank" rel="noopener noreferrer" className='ml-2 underline'> Website Designed by aaragroups</a>
      </div>
    </div>
  );
}

export default Footer;
