import { useEffect, useState } from 'react';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import { baseURL } from 'Configs/axios';

const Blogs = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()

  const blogFn = async () => {
    try {
      const response = await axios.get(`${baseURL}/blog/blog-api/`,
        {
          headers: {
            Authorization: 'Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0',
          },
        });
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleImageClick = (slug) => {
    navigate(`/blogs/${slug}`)
  }
  useEffect(() => {
    blogFn();
  }, []);


  const removeHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+>/gi, '')
  }


  const truncateContent = (content, wordLimit) => {
    const plainText = removeHtmlTags(content)
    const words = plainText.split(/\s+/)
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...'
    }
    return plainText
  }

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-white">
      {/*  {loading ? (
        <div className="flex items-center justify-center h-full">
          <div className="w-8 h-8 border-t-4 border-b-4 border-blue-500 rounded-full loader animate-spin"></div>
        </div>
      ) : (
        <div className="grid grid-cols-3">
          {data.map((blog) => (
            <div key={blog.id} className="flex flex-col items-center justify-center p-6 pt-10">
              {blog.images && (
                <img
                  src={blog.images}
                  alt={blog.title}
                  className=""
                />
              )}
              <p className="p-2 text-2xl font-bold text-black">{blog.title}</p>
              <p
                dangerouslySetInnerHTML={{ __html: blog.Content }}
                className="w-full p-2 text-gray-800"
              />
            </div>
          ))}
        </div>
      )} */}
      <div className="container flex flex-col p-4 text-lg bg-white lg:p-6 ">
        <div className="flex flex-col w-full gap-10 md:flex-row">
          <div className="flex flex-col md:w-[70%] w-[100%] ">
            {data.map((detail) => (
              <div
                className="flex flex-col gap-3 text-black"
                key={detail.id}
                onClick={() => handleImageClick(detail.slug)}
              >
                <div className='pt-10'>
                  <LazyLoadImage
                    src={detail.images}
                    alt=""
                    className="  lg:h-[70vh] p-2 md:h-[50vh] h-[33vh] w-[100%] border-4 "
                  /></div>
                <div className="w-[90%] text-2xl font-bold">{detail.title}</div>
                <p className="text-gray-600 w-[100%] text-lg p-2">
                  {truncateContent(detail.Content, 54)}
                </p>
                <p className="p-2 text-lg text-center text-white bg-blue-500 cursor-pointer w-36">Read More</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col text-black pt-10 md:w-[50%] w-[100%] ">
            <p className="text-4xl font-bold">
              Adwolt provides the Heighest Quality PVC Wires and Cable
            </p>
            <p className="text-lg ">
              Adwolt is the manufacturer of the highest quality PVC Wires and Cables. Besides high quality and durability, our products are much more cost-effective and we provide you superior Fire resistant wires for extra safety. We use Flame Retardant PVC that it do not catch fire easily. Our Eco friendly wires saves electricity and cuts down on long tern expenditure.
            </p>
            <div className="flex flex-col pt-5 lg:pt-20 md:pt-10">

              <p className="font-bold text-2xl text-[#787878]">BLOG -</p>
              <p className="text-[#5d5c5b] text-xl">Popular Posts</p>
            </div>

            {data.map((title) => (
              <div
                className="flex flex-col pt-10 md:w-[50%] w-[100%] "
                key={''}
                onClick={() => handleImageClick(title.slug)}
              >
                <p className="font-bold cursor-pointer">{title.title}</p>
                <p className="text-[#5d5c5b] ">Nov 20, 2023</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
