import axiosInstance from 'Configs/axios'
import { API_URLS } from 'Configs/urls'

export const contactUsDataFn = async () => {
  const { data } = await axiosInstance.get('/banners')
  return data
}

export const postFormDataFn = async (reqBody) => {
  try {
    const data = await axiosInstance.post(API_URLS.constactUs, reqBody);
    console.log(data);
    return data.data;
  } catch (error) {
    return error;
  }
}
