
import NotFound from 'NotFound'
import { AboutUs, ContactUs, Home } from 'Pages'
import Blogs from 'Pages/Blogs'
import BlogsDetails from 'Pages/Blogs/blogdetails'
import Gallery from 'Pages/Gallery'

import Products from 'Pages/Products/index '
//import Service from 'Pages/Service'

/**
 * Array of route objects defining the routes for the application.
 * @type {Array<{
 *   id: number,
 *   path: string,
 *   element: JSX.Element,
 *   title: string,
 *   status: boolean
 * }>}
 */
const routes = [
  {
    id: 0,
    path: '*',
    element: <NotFound />,
    title: '404 Not Found',
    status: true,
  },
  {
    id: 1,
    path: '/',
    element: <Home />,
    title: 'Home',
    status: true,
  },
  {
    id: 2,
    path: '/about-us',
    element: <AboutUs />,
    title: 'About Us',
    status: true,
  },
  {
    id: 3,
    path: '/contact-us',
    element: <ContactUs />,
    title: 'Contact Us',
    status: true,
  },
  {
    id: 4,
    path: '/products',
    element: <Products />,
    title: 'products',
    status: true,
  },
  {
    id: 5,
    path: '/gallery',
    element: <Gallery />,
    title: 'Gallery',
    status: true,
  },
  {
    id: 6,
    path: '/blogs',
    element: <Blogs />,
    title: 'blogs',
    status: true,
  },
  {
    id: 7,
    path: '/blogs/:slug',
    element: <BlogsDetails />,
    title: 'Blog Details',
    status: true,
  },
  /* {
    id: 7,
    path: '/services',
    element: <Service />,
    title: 'services',
    status: true,
  }, */
]

export default routes
