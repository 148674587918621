import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Divider, TextField } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import axiosInstance, { baseURL } from "Configs/axios";

const BlogsDetails = () => {
    const { slug } = useParams();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [CommentsList, setCommentsList] = useState([]);

    const BlogsDetailsFn = async () => {
        try {
            const response = await axios.get(
                `${baseURL}/blog/blog-api/?slug=${slug}`,
                {
                    headers: {
                        Authorization: 'Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0',
                    },
                }
            );

            if (response.data && response.data.data) {
                setData(response.data.data);
            } else {
                console.error('Unexpected response structure:', response);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        BlogsDetailsFn();

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }, [slug]);

    const formik = useFormik({
        initialValues: {
            user_name: "",
            email: "",
            website_url: "",
            comment: "",
        },
        onSubmit: async (values) => {
            const reqBody = {
                post_id: data?.id,
                name: values.user_name,
                email: values.email,
                website_url: values.website_url,
                comment: values.comment,
                store_id: 8,
            };
            formik.resetForm();
            axiosInstance
                .post(`/blog/post-comment-blog-api/`, reqBody, {
                    headers: {
                        Authorization: "Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0",
                    },
                })
                .then((res) => {
                    alert(res.data.message);
                })
                .catch((err) => {
                    alert(err.response.data.message);
                });
        },
    });

    const commentList = async (ids) => {
        const res = await axiosInstance.get(`/blog/comment-list-blog-api/`, {
            params: { post_id: ids },
            headers: {
                Authorization: "Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0",
            },
        });
        setCommentsList(res.data.data);
    };

    useEffect(() => {
        data?.id && commentList(data?.id);
    }, [data?.id]);

    if (loading) {
        return <div className="flex items-center justify-center h-full">Loading...</div>;
    }

    if (!data) {
        return <div className="flex items-center justify-center h-full">No data found</div>;
    }

    return (
        <div className="flex flex-col items-center min-h-screen !text-black bg-white">
            <Helmet>
                <title>{data?.title}</title>
                <meta name="description" content={data?.sub_title} />
                <meta name="keywords" content={data?.meta_keyword} />
            </Helmet>

            <div className="lg:w-[70%] w-full flex flex-col items-center lg:p-6 p-2 bg-white">
                {data?.images && (
                    <LazyLoadImage
                        src={data?.images}
                        alt={data?.title}
                        className=" w-full md:h-[600px] h-[300px] border-4 p-2"
                    />
                )}
                <div className="flex flex-col text-black">
                    <h1 className="text-3xl font-bold text-center">{data?.title}</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: data?.Content }}
                        className="flex flex-col gap-5 p-2 mt-4 text-xl font-normal text-gray-800"
                    />
                </div>
            </div>

            <div className=" flex flex-col gap-5 lg:p-6  p-2 lg:w-[70%] w-full !text-black">

                <form
                    className="flex flex-col gap-5 px-[15%]"
                    onSubmit={formik.handleSubmit} >
                    <p className="text-2xl font-bold text-center">{CommentsList?.length} Comments</p>
                    {CommentsList?.map((comment) => (
                        <div key={comment.id}>
                            <span className="flex items-start gap-2 my-10">
                                <Avatar src="broken" alt={comment.name} />
                                <span className="flex flex-col w-full gap-2">
                                    <span className="flex justify-between w-full">
                                        <p className="font-bold">{comment.name}</p>
                                        <p className="text-xs">{comment?.created_at?.slice(0, 10)}</p>
                                    </span>
                                    <p className="text-xs">{comment.comment}</p>
                                    <p className="text-sm ">
                                        <strong className="text-base">Reply: </strong>{comment?.comment_replies[0]?.reply}
                                    </p>
                                </span>
                            </span>
                            <Divider />
                        </div>
                    ))}
                    <p className="my-5 text-2xl font-bold text-center">Leave A Comment</p>
                    <TextField
                        variant="outlined"

                        type="text"
                        name="user_name"
                        id="user_name"
                        sx={{
                            border: '2px solid black',
                            '& .MuiInputBase-input': { color: 'black' }, // Text color
                            '& .MuiInputBase-input::placeholder': { color: 'black' }, // Placeholder color
                        }}
                        placeholder="Name"
                        value={formik.values.user_name}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        variant="outlined"

                        type="email"
                        name="email"
                        id="email"

                        sx={{
                            border: '2px solid black',
                            '& .MuiInputBase-input': { color: 'black' }, // Text color
                            '& .MuiInputBase-input::placeholder': { color: 'black' }, // Placeholder color
                        }}
                        placeholder="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        type="text"
                        name="website_url"
                        id="website_url"
                        sx={{
                            border: '2px solid black',
                            '& .MuiInputBase-input': { color: 'black' }, // Text color
                            '& .MuiInputBase-input::placeholder': { color: 'black' }, // Placeholder color
                        }}
                        placeholder="Website"
                        value={formik.values.website_url}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        multiline
                        rows={4}

                        name="comment"
                        id="comment"
                        sx={{
                            border: '2px solid black',
                            '& .MuiInputBase-input': { color: 'black' }, // Text color
                            '& .MuiInputBase-input::placeholder': { color: 'black' }, // Placeholder color
                        }} // Set black border
                        placeholder="Comment"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                    />
                    <button
                        type="submit"
                        className="w-fit border p-2 rounded-xl hover:bg-[#0d6dfc] hover:text-black bg-sky-500 text-white border-b-4 border-b-[#5942bd]"
                    >
                        Send Your Request
                    </button>
                </form>
            </div>

        </div>
    );
};

export default BlogsDetails;
