import * as Yup from 'yup'

/**
 * Schema for validating user sign-up data.
 * @type {import('yup').ObjectSchema}
 */
const ContactSchema = Yup.object().shape({
  first_name: Yup.string().required('First Name is required'),

  email: Yup.string().email('Invalid email address').required('Email is required'),
  mobile_no: Yup.string().matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),

})

export default ContactSchema;