import { Email, LocationOn } from '@mui/icons-material';
import { useFormik } from 'formik';

import { useMutation } from 'react-query';

import { useState } from 'react';
import { postFormDataFn } from 'Services/ContactUs';
import { Link } from 'react-router-dom';
import ContactSchema from 'Schemas';
import ReachToUs from 'Pages/AboutUs/reachtous';
import logo from "../../Components/Common/logo1.png"

const ContactUs = () => {
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const { mutate: postFormData } = useMutation(postFormDataFn, {
    onSuccess: (data) => {
      setLoading(false);
      if (data?.response_code === 200) {
        setSuccessMessage('Form Successfully Sent.');
        handleReset();
      }
    },
    onError: (error) => {
      setLoading(false);
      console.error("Error response:", error);
    }
  });

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    description: ""
  };

  const { values, handleBlur, handleChange, touched, handleSubmit, errors, handleReset } = useFormik({
    initialValues,
    validationSchema: ContactSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      Object.keys(values).forEach(key => formData.append(key, values[key]));
      postFormData(formData);
    }
  });
  return (
    <div>
      {/* Background and Title Section */}
      <div className="relative w-full md:h-[450px] ">
        <img
          src="https://erplab.s3.amazonaws.com/fiber-wire/Power-Cables-Step-Cables.jpg"
          alt="Products Background"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">
          <p className="text-4xl font-extrabold text-white">CONTACT US</p>
        </div>
      </div>

      {/* Form Section */}
      <div className="flex flex-col items-center justify-center p-4 bg-white">
        <div className="w-full grid grid-cols-1 xl:grid-cols-[40%_60%] md:w-[80%] lg:w-[65%] gap-10 pt-14 pb-10">
          <div className='flex flex-col gap-5 text-black !text-base'>

            <p className='text-2xl font-semibold'>Contact Info</p>
            <div className='flex gap-5 font-medium'>
              <p className='flex justify-center items-center text-white bg-gradient-to-r from-[#c5393d] to-[#c5393d] h-10 w-10'>
                <LocationOn />
              </p>
              <p className='flex-1'>India</p>
            </div>
            <div className='flex gap-5 font-medium'>
              <p className='flex justify-center items-center text-white bg-gradient-to-r from-[#c5393d] to-[#c5393d] h-10 w-10'>
                <Email />
              </p>
              <Link to="mailto:enquiry@adwoltelectricals.com">enquiry@adwoltelectricals.com</Link>
            </div>
            <div className='flex items-center justify-center'>
              <img src={logo} alt='' className='h-40 md:h-60' />
            </div>
          </div>

          <div className='flex flex-col items-center justify-center text-black'>
            <div className="w-full bg-white">
              <p className="text-2xl font-bold text-black">Get in touch</p>
              <form className="flex flex-col mt-4 text-base text-gray-600" onSubmit={handleSubmit}>
                <div className='gap-6'>
                  <div className='flex flex-col'>
                    <label htmlFor="first_name" className='text-base font-bold'>Name</label>
                    <input
                      id="first_name"
                      type="text"
                      name="first_name"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className='h-10 border border-gray-300'
                    />
                    {errors.first_name && touched.first_name ? <div className="text-sm text-red-600">{errors.first_name}</div> : null}
                  </div>

                  {/* <div className='flex flex-col'>
                    <label htmlFor="last_name" className='text-base font-bold'>Last Name</label>
                    <input
                      id="last_name"
                      type="text"
                      name="last_name"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className='h-10 border border-gray-300'
                    />
                    {errors.last_name && touched.last_name ? <div className="text-sm text-red-600">{errors.last_name}</div> : null}
                  </div> */}
                </div>

                <div className='flex flex-col'>
                  <label htmlFor="email" className='mt-5 text-base font-bold'>Email</label>
                  <input
                    id="email"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='h-10 w-[100%] border border-gray-300'
                  />
                  {errors.email && touched.email ? <div className="text-sm text-red-600">{errors.email}</div> : null}

                  <label htmlFor="mobile" className='mt-5 text-base font-bold'>Phone</label>
                  <input
                    id="mobile"
                    type="text"
                    name="mobile"
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='h-10 w-[100%] border border-gray-300'
                  />
                  {errors.mobile && touched.mobile ? <div className="text-sm text-red-600">{errors.mobile}</div> : null}

                  <label htmlFor="description" className='mt-5 text-base font-bold'>Comment or Message</label>
                  <textarea
                    id="description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className='border border-gray-300'
                    rows="4"
                    cols="50"
                  />
                </div>

                <div className='flex items-center justify-center mt-4'>
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <div className="w-8 h-8 border-t-4 border-b-4 border-blue-500 rounded-full loader animate-spin"></div>
                    </div>
                  ) : (
                    <button type="submit" className='px-8 py-3 bg-gradient-to-r from-pink-700 to-[#da3035] text-white text-base'>
                      Submit
                    </button>
                  )}
                </div>
              </form>

              {/* Success Message */}
              {successMessage && (
                <div className="mt-4 font-bold text-green-600">
                  {successMessage}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='w-full lg:w-[70%]'>
          <ReachToUs />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
