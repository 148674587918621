import React, { useEffect, useState } from 'react';
import pic1 from "./pic1.jpg";
import pic2 from "./pic3.jpg";
import pic3 from "./pic4.jpg";
import pic4 from "./pic5.jpg";
import pic5 from "./pic6.jpg";
import Slider from 'react-slick';
import { Search } from '@mui/icons-material';
import axios from 'axios';

const SliderPage = () => {
    const [data, setData] = useState([]);
    const galleryFn = async () => {

        const response = await axios.get(`https://m1blog.aaragroups.com/blog/gallery-api/`,
            {
                headers: {
                    Authorization: 'Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0',
                },
            });
        setData(response.data.data);
    };
    useEffect(() => {
        galleryFn();
    }, []);


    const sliderimages = [
        { id: 1, image: pic1, text: "GOVT" },
        { id: 2, image: pic2, text: "Hotel" },
        { id: 3, image: pic3, text: "Architech" },
        { id: 4, image: pic4, text: "Design" },
        { id: 5, image: pic5, text: "GOVT" },
    ];








    const [currentSlide, setCurrentSlide] = useState(3);

    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange: (current, next) => setCurrentSlide(next % sliderimages.length),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className="p-4 bg-red-500 slider-container">
            <style>
                {`
                    .fade-up {
                        animation: fadeInUp 2s ease forwards;
                    }
                    .fade-down {
                        animation: fadeInDown 2s ease forwards;
                    }
                    @keyframes fadeInUp {
                        from {
                            transform: translateY(20px);
                            opacity: 0;
                        }
                        to {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                    @keyframes fadeInDown {
                        from {
                            transform: translateY(-20px);
                            opacity: 0;
                        }
                        to {
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                `}
            </style>
            <Slider {...settings}>
                {data?.map((i, index) => {
                    const isActive = currentSlide === index;
                    return (
                        <div
                            key={i.id}
                            className='relative flex items-center justify-center overflow-hidden'
                            style={{ margin: '0 5px' }}
                        >
                            <img
                                src={i.image}
                                alt=''
                                className={`object-cover transition-opacity duration-500 
                                ${isActive ? 'h-96 w-96' : 'h-72 w-60'} 
                                ${isActive ? 'opacity-100' : 'opacity-30'}`}
                            />
                            {isActive && (
                                <>
                                    <div className={`absolute top-0 p-4 bg-black bg-opacity-50 right-[32px] cursor-pointer ${isActive ? 'fade-up' : ''}`}>
                                        <Search className='text-2xl text-white' />
                                    </div>
                                    <div className={`bottom-0 p-2 text-center text-white bg-black bg-opacity-50 w-96 ${isActive ? 'fade-down' : ''}`}>
                                        <p>{i.title}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default SliderPage;
