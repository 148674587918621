import { Call, Facebook, Instagram, LinkedIn, Menu, Twitter, WhatsApp } from '@mui/icons-material';
import { Drawer, IconButton, List, ListItem, ListItemText, Paper } from '@mui/material';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from "../logo1.png";

const Header = ({ setMode, mode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleScroll = () => {
    setScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const menuItems = [
    { text: 'HOME', path: '/' },
    { text: 'ABOUT US', path: '/about-us' },
    { text: 'PRODUCT', path: '/products' },
    { text: 'GALLERY', path: '/gallery' },
    { text: 'BLOG', path: '/blogs' },
    { text: 'CONTACT US', path: '/contact-us' },
  ];

  return (
    <Paper className="flex flex-col !rounded-none !sticky top-0 z-50 text-black">
      <div className={`flex justify-between p-1 transition-colors duration-300 ${scrolled ? 'bg-white' : ''} secondpart`}>
        <div className='flex items-center justify-center h-16 px-5'>
          <Link to="/" className="cursor-pointer">
            <img src={logo} alt='' className='w-[100px] mx-2' />
          </Link>
        </div>
        <div className="items-center hidden gap-3 font-bold text-black lg:flex menusection">
          {menuItems.map(item => (
            <Link key={item.text} to={item.path} className={`block float-left p-3 ${scrolled ? 'text-black' : 'text-white'} hover:text-black hover:bg-white`}>
              {item.text}
            </Link>
          ))}
        </div>
        <div className="flex items-center justify-end px-4 lg:hidden">
          <IconButton onClick={toggleDrawer(true)}>
            <Menu className='text-black' />
          </IconButton>
        </div>
      </div>
      <Drawer
        anchor='right'
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'white',
            color: 'red',
          },
        }}
        className='h-screen'
      >
        <List>
          {menuItems.map(item => (
            <ListItem
              component={Link}
              to={item.path}
              key={item.text}
              onClick={toggleDrawer(false)}
              sx={{ color: 'red' }}
            >
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
    </Paper>
  );
};

export default Header;
