import React from 'react'
import ReachToUs from './reachtous'

import Faq from 'Pages/Faq'

const AboutUs = () => {
  return (
    <div className="">
      <div className="relative w-full overflow-hidden md:h-[450px]">
        <img
          src="https://erplab.s3.amazonaws.com/fiber-wire/Power-Cables-Step-Cables.jpg"
          alt="Products Background"
          className="object-cover w-full h-full"
        />
        <div className="absolute inset-0 bg-black opacity-25"></div>
        <div className="absolute inset-0 flex px-[20%] items-center">

          <p className="text-4xl font-extrabold text-white">ABOUT US</p>

        </div>
      </div>
      <div className="flex flex-col items-center justify-center gap-10 bg-white ">
        <div className="flex flex-col justify-center items-center w-full lg:w-[70%] p-4 bg-white ">
          <p className="p-2 text-2xl font-semibold text-center text-black md:text-3xl lg:text-4xl">
            Adwolt provides the Heighest Quality PVC Wires and Cable
          </p>
          <p className="text-[#7A7A7A] w-[90%] text-lg">
            Adwolt is the manufacturer of the highest quality PVC Wires and Cables. Besides high quality and durability, our products are much more cost-effective and we provide you superior Fire resistant wires for extra safety. We use Flame Retardant PVC that it do not catch fire easily. Our Eco friendly wires saves electricity and cuts down on long tern expenditure. Each corner we secure holds tremendous value to us because we understand that behind
            every successful installation lies our client's trust and faith in our services. Our
            journey began with a simple belief-leveraging technology to create safe environments. As
            we continue to grow, so does this belief which fuels.
          </p>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center p-4 gap-10 w-full lg:w-[70%]">
          <div className="p-4  flex-col flex !justify-center !items-center w-full sm:w-[50%] ">
            <img
              src="https://erplab.s3.amazonaws.com/fiber-wire/pp.jpg"
              alt=""
              className="w-48 h-48 p-4"
            />
            <p className="text-3xl font-semibold text-black"> <strong className='text-[#da3035]'>Who We</strong> Are</p>
            <p className=" text-[#7A7A7A]">
              We are a leading manufacturer specializing in a comprehensive range of wire and cable solutions. Our products cater to diverse sectors, including construction, telecommunications, automotive, and industrial applications.
            </p>
          </div>
          <div className="p-4 flex-col flex !justify-center !items-center  w-full sm:w-[50%] ">
            <img
              src="https://erplab.s3.amazonaws.com/fiber-wire/product-vision-mission.jpg"
              alt=""
              className="w-48 h-48 p-4"
            />
            <p className="text-3xl font-semibold text-black">Our <strong className='text-[#da3035]'>Mission</strong></p>
            <p className="text-[#7A7A7A]">
              At Adwolt, our mission is to deliver superior quality and performance in every product we offer. We are committed to adhering to the highest industry standards, ensuring that our wires and cables not only meet but exceed customer expectations.
            </p>
          </div>
        </div>
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <Faq />
        </div>
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          {/*  <TopBrand /> */}
        </div>
        {/* <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <Services />
        </div> */}
        <div className='p-4 w-full lg:w-[70%] grid place-content-center'>
          <ReachToUs />
        </div>
      </div>

    </div>
  )
}

export default AboutUs
