import Slider from 'react-slick';
import { useState, useEffect } from 'react';
import axiosInstance from 'Configs/axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Rating } from '@mui/material';

const Brands = () => {
  const [value, setValue] = useState(4);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get("/client-feedback-api/", {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Token 5fa8ea24df88174598a16c1d9d02c745c2d676f0",
          },
        });
        console.log(response.data);
        setData(response?.data?.data);
      } catch (err) {
        setError(err);
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div className='flex flex-col items-center justify-center gap-5'>
      <p className="text-3xl font-bold text-center text-black md:text-4xl">
        <span className="text-[#93c83f]">OUR</span> CLIENTS
      </p>

      <div className="">
        <Slider {...settings} className="w-[80vw]">
          {data?.map((i) => {
            return (
              <div
                key={i.id} // Assuming each item has a unique `id`
                className="border relative z-50 shadow shadow-red-300 !overflow-hidden !bg-[#e8e8e8] h-[24rem] py-7 !w-[95%] px-4 rounded-2xl"
              >
                <div className="flex flex-col gap-3">
                  <div className="flex gap-2">
                    <LazyLoadImage
                      src={i.client_image}
                      alt=""
                      className="w-12 h-12 rounded-full"
                    />
                    <div>
                      <p className="font-serif text-lg font-semibold">
                        {i.client}
                      </p>
                      <p className="text-slate-600 h-fit">{i.designation}</p>
                    </div>
                  </div>
                  <Rating
                    name="simple-controlled"
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                  />
                  <p className="text-xs font-semibold break-words">
                    {i.comment}
                  </p>
                </div>
                <LazyLoadImage
                  src={"https://aaraglobal.com/assets/images/quote.webp"}
                  alt=""
                  className="absolute right-0 h-16 top-5 opacity-30"
                />
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
}

export default Brands;
